import { env } from '@/env/env';
import { MUTATION_ADD_TOKEN } from '@/store/store';
import { httpService } from './HttpService';

class FileService {
  init({ a, file, tokenModal }) {
    this.a = a;
    this.file = file;
    this.tokenModal = tokenModal;

    this.file.value.addEventListener('change', (event) => {
      const file = event.target.files[0];

      if (!file) return;

      httpService.uploadPost(file).then((resp) => {
        const customDataId = resp.data;
        window.store.commit(MUTATION_ADD_TOKEN, {
          token: customDataId,
          fname: file.name,
          done: false,
        });

        this.file.value.value = '';
      });
    });
  }

  exportToxlsx() {
    const token = window.store?.state?.selectedToken;
    if (!token) {
      httpService
        .post(
          `${env.api.workplaces_xlsx}`,
          {},
          {
            responseType: 'blob',
          }
        )
        .then((resp) => {
          if (!resp?.data) return;
          const url = URL.createObjectURL(resp.data);

          this.a.value.href = url;
          this.a.value.download = `workplaces.xlsx`;
          this.a.value.click();

          window.URL.revokeObjectURL(url);
        });
    } else {
      httpService
        .get(`${env.api.custom_workplaces}${token}/xlsx/`, {
          responseType: 'blob',
        })
        .then((resp) => {
          if (!resp?.data) return;
          const url = URL.createObjectURL(resp.data);

          this.a.value.href = url;
          this.a.value.download = `workplaces.xlsx`;
          this.a.value.click();

          window.URL.revokeObjectURL(url);
        });
    }
  }

  downloadTemplate() {
    httpService
      .get(`${env.api.template}`, {
        responseType: 'blob',
      })
      .then((resp) => {
        if (!resp?.data) return;
        const url = URL.createObjectURL(resp.data);

        this.a.value.href = url;
        this.a.value.download = `template.xlsx`;
        this.a.value.click();

        window.URL.revokeObjectURL(url);
      });
  }

  upload() {
    this.file.value.click();
  }

  toggleTokenModal() {
    this.tokenModal.value.click();
  }
}

export const fileService = new FileService();
