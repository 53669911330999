const base = '/heatmap_backend/';

export const getEnv = ({ baseUrl }) => ({
  baseUrl,
  api: {
    get_filters: `${base}get_filters/`,
    get_legend: `${base}get_legend/`,
    get_hexes: `${base}get_hexes/`,
    get_heatmap_hexes: `${base}get_heatmap_hexes/`,

    regions: `${base}regions/`,
    towns: `${base}towns/`,

    workplaces: `${base}workplaces/`,
    workplaces_xlsx: `${base}workplaces/xlsx/`,

    template: `${base}static/template.xlsx`,

    custom_workplaces: `${base}workplaces/custom/`,

    groups_projects: `${base}groups_projects/`,
    projects: `${base}projects/`,

    bbox_town: `${base}town/bbox/`,
    bbox_region: `${base}region/bbox/`,
  },
});
