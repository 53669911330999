<template>
  <nav class="navbar d-flex flex-column gap-3 position-absolute bg-light map-navbar">
    <div class="d-flex flex-row flex-nowrap gap-3 map-navbar__first-row">
      <DropdownListComponent
        :title="store.state.currentRegion?.name || 'Регион'"
        :show-reset-button="Boolean(store.state.currentRegion)"
        :items="store.state.regionsList"
        :get-item-text="(region) => region.name"
        @select="(region) => store.commit(MUTATION_SET_CURRENT_REGION, region.id)"
        @reset="() => store.commit(MUTATION_RESET_CURRENT_REGION)"
      ></DropdownListComponent>

      <DropdownListComponent
        :title="store.state.currentTown?.name || 'Город'"
        :show-reset-button="Boolean(store.state.currentTown)"
        :items="store.state.towns?.[store.state.currentRegion?.id]?.list ?? []"
        :get-item-text="(town) => town.name"
        @select="(town) => store.commit(MUTATION_SET_CURRENT_TOWN, town.id)"
        @reset="() => store.commit(MUTATION_RESET_CURRENT_TOWN)"
      ></DropdownListComponent>

      <DropdownListComponent
        :title="store.state.currentHexType?.text"
        :title-icon="store.state.currentHexType?.isHeatmapHex ? 'bi-map' : 'bi-hexagon'"
        :items="store.state.hexTypes"
        :item-icon="(type) => (type.isHeatmapHex ? 'bi-map' : 'bi-hexagon')"
        :get-item-text="(type) => type.text"
        @select="(type) => store.commit(MUTATION_SET_CURRENT_HEX_TYPE, type)"
      ></DropdownListComponent>

      <div>
        <button
          class="btn"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse__header_filters"
          aria-expanded="false"
          aria-controls="collapse__header_filters"
        >
          Фильтры
        </button>
      </div>

      <!-- ... -->
      <div class="dropdown">
        <button class="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="bi bi-three-dots"></i>
        </button>
        <a class="d-none" target="_blank" ref="a"></a>
        <input class="d-none" type="file" ref="file" />
        <div class="d-none" ref="tokenModal" data-bs-toggle="modal" data-bs-target="#tokenModal"></div>
        <ul class="dropdown-menu">
          <button class="dropdown-item" type="button">
            <i class="bi bi-cloud-arrow-up icon-text" @click="fileService.upload"> Загрузить данные </i>
          </button>
          <li class="dropdown-item">
            <i class="bi bi-cloud-arrow-down icon-text" @click="fileService.downloadTemplate"> Скачать шаблон </i>
          </li>
          <li class="dropdown-item">
            <i class="bi bi-cloud-arrow-down icon-text" @click="fileService.exportToxlsx"> Экспорт в xlsx </i>
          </li>
          <button class="dropdown-item" type="button" data-bs-toggle="modal" data-bs-target="#helpModal">
            <i class="bi bi-question-circle icon-text"> Справка </i>
          </button>

          <li v-if="store.state?.tokens?.length"><hr class="dropdown-divider" /></li>

          <div v-for="token in store.state.tokens" :key="token" class="d-flex flex-row">
            <button type="button" class="btn dropdown-item" @click="store.commit(MUTATION_USE_TOKEN, token)">
              <div v-if="!token.done" class="icon-text">
                <div class="spinner-border spinner-border-sm"></div>
                {{ token.fname }}
              </div>

              <i v-if="token.done" class="bi bi-table icon-text">
                {{ token.fname }}
              </i>
            </button>
            <i class="bi bi-info-circle dropdown-item px-3" @click="() => store.commit(MUTATION_SHOW_TOKEN, token)"> </i>
            <i class="bi bi-x-circle dropdown-item px-3" @click="() => store.commit(MUTATION_FORGOT_TOKEN, token.token)"> </i>
          </div>
        </ul>
      </div>
    </div>

    <div class="collapse" id="collapse__header_filters">
      <div class="d-flex flex-row gap-3">
        <div class="p-2">
          <!-- <p>Тип контракта</p> -->

          <div class="form-check" v-for="item in store.state.allContracts" :key="item">
            <input class="form-check-input btn-secondary" type="checkbox" v-model="selectedContractTypes" :value="item.id" :id="item.id" />
            <label class="form-check-label btn-secondary" :for="item.id"> {{ item.name }} </label>
          </div>
        </div>

        <div class="map-navbar__second-row__item">
          <DropdownListComponent
            :title="store.state.currentProjectGroup?.name ?? 'Группа проектов'"
            :show-reset-button="Boolean(store.state.currentProjectGroup)"
            :items="store.state.projectGroups?.list"
            :get-item-text="(group) => group.name"
            @select="(group) => store.commit(MUTATION_SET_CURRENT_PROJECT_GROUP, group.id)"
            @reset="() => store.commit(MUTATION_RESET_CURRENT_PROJECT_GROUP)"
          ></DropdownListComponent>
          <DropdownListComponent
            :title="store.state.currentProject?.name ?? 'Проект'"
            :show-reset-button="Boolean(store.state.currentProject)"
            :items="store.state.projects[store.state.currentProjectGroup?.id]?.list"
            :get-item-text="(project) => project.name"
            @select="(project) => store.commit(MUTATION_SET_CURRENT_PROJECT, project.id)"
            @reset="() => store.commit(MUTATION_RESET_CURRENT_PROJECT)"
          ></DropdownListComponent>
        </div>

        <div class="d-flex flex-column">
          <VDatePicker trim-weeks borderless color="gray" v-model="dateStart" :popover="popover" mode="date">
            <template #default="{ togglePopover }">
              <button class="btn map-navbar__second-row__item" type="button" @click="togglePopover">От {{ formatDate(dateStart) }}</button>
            </template>
          </VDatePicker>

          <VDatePicker trim-weeks borderless color="gray" v-model="dateEnd" :popover="popover" mode="date">
            <template #default="{ togglePopover }">
              <button class="btn map-navbar__second-row__item" type="button" @click="togglePopover">До {{ formatDate(dateEnd) }}</button>
            </template>
          </VDatePicker>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import DropdownListComponent from '@/components/DropdownListComponent.vue';
import { fileService } from '@/services/FileService';
import {
  MUTATION_FORGOT_TOKEN,
  MUTATION_RESET_CURRENT_PROJECT,
  MUTATION_RESET_CURRENT_PROJECT_GROUP,
  MUTATION_RESET_CURRENT_REGION,
  MUTATION_RESET_CURRENT_TOWN,
  MUTATION_SET_CURRENT_CONTRACT_TYPE,
  MUTATION_SET_CURRENT_HEX_TYPE,
  MUTATION_SET_CURRENT_PROJECT,
  MUTATION_SET_CURRENT_PROJECT_GROUP,
  MUTATION_SET_CURRENT_REGION,
  MUTATION_SET_CURRENT_TOWN,
  MUTATION_SET_DATE_END,
  MUTATION_SET_DATE_START,
  MUTATION_SHOW_TOKEN,
  MUTATION_USE_TOKEN,
} from '@/store/store';
import { onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

// для скачивания файлов
const a = ref(null);
const file = ref(null);
const tokenModal = ref(null);

const popover = ref({
  visibility: 'click',
  placement: 'left-start',
});

const selectedContractTypes = ref([...store.state.contract]);

const dateStart = ref(new Date(store.state.dateStart));
const dateEnd = ref(new Date(store.state.dateEnd));

const formatDate = (date) => date.toJSON().split('T')[0];

onMounted(() => {
  fileService.init({ a, file, tokenModal });
});

watch(selectedContractTypes, (contract) => {
  store.commit(MUTATION_SET_CURRENT_CONTRACT_TYPE, contract);
});

watch(dateStart, (date) => {
  store.commit(MUTATION_SET_DATE_START, date);
});
watch(dateEnd, (date) => {
  store.commit(MUTATION_SET_DATE_END, date);
});
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';

.map-navbar {
  padding: $navbarVPadding $navbarHPadding;
  border-radius: $headerBorderRadius;
  height: auto;

  &__first-row {
    height: 40px;
  }

  &__second-row {
    &__item {
      height: 40px;
    }
  }
}

.icon-text {
  display: inline-flex;
  align-items: center;
  font-style: normal;
  gap: 8px;
}

li,
i {
  cursor: pointer;
}
</style>
