import { hookAllFilters } from '@/services/UpdateHooks';

export const mSetCurrentContact = (state, contract) => {
  state.contract = contract;
  hookAllFilters();
};

export const mSetDateStart = (state, date) => {
  state.dateStart = date;
  hookAllFilters();
};
export const mSetDateEnd = (state, date) => {
  state.dateEnd = date;
  hookAllFilters();
};
