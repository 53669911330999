<template>
  <div
    class="px-2 my-2 overflow-y-scroll overflow-x-hidden"
    data-bs-spy="scroll"
    data-bs-target="#scroll-spy-link"
    data-bs-smooth-scroll="true"
    data-bs-root-margin="0px 0px 40%"
  >
    <hr ref="dividerRef" class="border border-4 opacity-75 divider" />
    <h1 v-if="props.list.length == 0 || props.fields?.length == 0" class="d-flex justify-content-center p-5">
      Нет данных для выбранного региона
    </h1>

    <table v-if="props.list.length && props.fields?.length" id="tableComponent" class="table table-hover w-100 map__table">
      <thead>
        <tr>
          <th v-for="field in props.fields" :key="field" @click="sortTable(field)">
            {{ field }}

            <span v-if="filterParams.key == field">
              <i :class="'bi ' + filterIconsMap[filterParams.order]"></i>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in filteredList" :key="item" @click="emit('selected', item)" :id="item.id">
          <td v-for="field in props.fields" :key="field">{{ item[field] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, onMounted, ref, watch } from 'vue';

const props = defineProps({
  fields: Array,
  list: Array,
});
const emit = defineEmits(['selected', 'onDividerInit']);

const dividerRef = ref(null);

const filterParams = ref({ key: 'id', order: true });
const filteredList = ref([]);

const filterIconsMap = {
  true: 'bi-arrow-down',
  false: 'bi-arrow-up',
};

onMounted(() => {
  emit('onDividerInit', dividerRef);

  filteredList.value = [...props.list];
});

const sortTable = (key) => {
  if (key === filterParams.value.key) {
    filterParams.value.order = !filterParams.value.order;
  } else {
    filterParams.value.key = key;
    filterParams.value.order = true;
  }

  const compareItems = (left, right, order) => {
    const leftInt = parseInt(left);
    const rightInt = parseInt(right);

    if (isNaN(leftInt) || isNaN(rightInt)) {
      return order ? left > right : left < right;
    }

    return order ? leftInt > rightInt : leftInt < rightInt;
  };

  filteredList.value = filteredList.value.sort((left, right) => (compareItems(left[key], right[key], filterParams.value.order) ? 1 : -1));
};

watch(
  () => props.list,
  (newValue) => {
    filteredList.value = newValue;

    if (props.fields) {
      sortTable(props.fields[0]);
    }
  }
);

watch(
  () => props.fields,
  (fields) => {
    if (filteredList.value?.length && fields?.length) {
      sortTable(fields[0]);
    }
  }
);
</script>

<style scoped lang="scss">
.divider {
  margin: -12px 0 0 -8px;
  cursor: row-resize;
  z-index: 12;
  position: absolute;
  width: 100vw;
  border-radius: 3px;
}
.table {
  margin: 0;

  thead {
    position: sticky;
    top: 0;
    background: white;
  }

  &__header {
    display: flex;

    flex-flow: row-reverse;
  }

  th {
    width: auto;
  }
}
</style>
