import { env } from '@/env/env';
import { httpService } from '@/services/HttpService';
import { hookAllFilters } from '@/services/UpdateHooks';
import { dictToList, getProjectGroupObject, getProjectObject } from '@/services/utils';

export const mFetchProjectGeoups = (state) => {
  httpService.post(env.api.groups_projects, {}).then(({ data }) => {
    state.projectGroups = {
      data,
    };
    if (data) {
      state.projectGroups.list = dictToList(data, (key) => getProjectGroupObject(key, state));
    }

    state.currentProjectGroup = null;
    state.currentProject = null;
  });
};

export const mSetCurrentProjectGroup = (state, id) => {
  state.currentProjectGroup = getProjectGroupObject(id, state);
  hookAllFilters();
  state.currentProject = null;

  if (!state.projects[id]) {
    httpService.post(`${env.api.projects}${id}/`, {}).then(({ data }) => {
      state.projects[id] = { data };
      if (data) {
        state.projects[id].list = dictToList(data, (key) => getProjectObject(id, key, state));
      }
    });
  }
};

export const mResetCurrentProjectGroup = (state) => {
  state.currentProjectGroup = null;
  state.currentProject = null;
  hookAllFilters();
};

export const mSetCurrentProject = (state, id) => {
  state.currentProject = getProjectObject(state.currentProjectGroup.id, id, state);
  hookAllFilters();
};
export const mResetCurrentProject = (state) => {
  state.currentProject = null;
  hookAllFilters();
};
