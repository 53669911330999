import { Subject } from 'rxjs';

/** Триггер для обновления хексов, отпраляем true, если надо перерисовать существующие хексы (игнорируем мемкэш) */
export const hexUpdate$ = new Subject();

/** Триггер для обновления легенды  */
export const legendUpdate$ = new Subject();

/** Триггер для обновления данных таблицы  */
export const tableUpdate$ = new Subject();

/** Триггер для обновления списка проектов */
export const projectsUpdate$ = new Subject();

export const hookAllFilters = () => {
  hexUpdate$.next(true);
  legendUpdate$.next();
  tableUpdate$.next();
};
