<template>
  <div class="modal fade" id="helpModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Как все устроено:</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body"><iframe :src="src" height="99%" style="border: none;"></iframe></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { env } from '@/env/env';

const src = `${env.baseUrl}/heatmap_backend/static/help.html`;
</script>

<style scoped lang="scss">
iframe {
  height: 99%;
  width: 99%;
  min-height: 500px;
  min-width: 600px;
}
</style>
