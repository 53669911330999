<template>
  <div class="modal fade" id="tokenModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Информация о загруженном файле:</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div>Имя файла: {{ (store.state.tokenInfo) ? store.state.tokenInfo.fname : ""  }}</div>
          <div>Загружено: {{ (store.state.tokenInfo) ? Date(store.state.tokenInfo.created_at).toLocaleString() : ""  }}</div>
          <div>Всего строк: {{ (store.state.tokenInfo) ? store.state.tokenInfo.cnt : ""  }}</div>
          <div>Обработано строк: {{ (store.state.tokenInfo) ? store.state.tokenInfo.resolved : ""  }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';

const store = useStore();
</script>

<style scoped lang="scss">
iframe {
  height: 99%;
  width: 99%;
  min-height: 500px;
  min-width: 600px;
}
</style>
