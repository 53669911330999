import { env } from '@/env/env';
import { hexService } from '@/services/HexService';
import { httpService } from '@/services/HttpService';
import { mapService } from '@/services/MapService';
import { cleanParams, getTypeObject, mapZoomToHexZoom } from '@/services/utils';

export const mFetchHexTypes = (state) => {
  httpService.get(env.api.get_filters).then(({ data }) => {
    state.rawHexTypes = { ...data.types };

    const hexTypes = Object.keys(data.types).reduce((sum, key) => {
      sum.push(getTypeObject(key, false, state));

      return sum;
    }, []);
    // hexTypes.push(this._getTypeObject('work', false));

    state.hexTypes = [
      ...hexTypes,
      ...hexTypes.map((i) => ({
        ...i,
        isHeatmapHex: true,
      })),
    ];

    mSetCurrentHexType(state, { key: data.defaul_type, isHeatmapHex: false });
  });
};

export const mSetCurrentHexType = (state, { key, isHeatmapHex }) => {
  state.currentHexType = getTypeObject(key, isHeatmapHex, state);

  hexService.updateType(key, isHeatmapHex);
  mFetchLegend(state);
};

export const mFetchLegend = (state, force = false) => {
  state.currentHexZoom = mapZoomToHexZoom(mapService.map.getZoom());
  if (!force && state.rawLegend[state.currentHexZoom]) {
    updateCurrentLegend(state);
    return;
  }

  const rawParams = cleanParams(window?.store?.getters?.filters);
  const params = new URLSearchParams();

  for (const key in rawParams) {
    if (key === 'contract_type') {
      rawParams[key].forEach((value) => {
        params.append(key, value);
      });
      continue;
    }

    params.append(key, rawParams[key]);
  }

  httpService
    .get(`${env.api.get_legend}${state.currentHexZoom}/?${params.toString()}`)
    .then(({ data }) => {
      state.rawLegend[state.currentHexZoom] = data;
      updateCurrentLegend(state);
    })
    .catch(console.log);
};

// others

const updateCurrentLegend = (state) => {
  state.currentLegend = state.rawLegend[state.currentHexZoom][state.currentHexType.key];
};

// https://mygig.promsoft.ru/heatmap_backend/get_legend/2/date_from=2024-04-08&date_to=2024-04-14&contract_type=services%2Csaas
