<template>
  <div class="btn-group">
    <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      <i class="bi me-2" :class="titleIcon"> </i>
      <span class="me-2">
        {{ props.title }}
      </span>
    </button>
    <button v-if="props.showResetButton" class="btn p-0">
      <i class="bi bi-x" @click="(e) => (e.stopPropagation(), emit('reset'))"></i>
    </button>

    <ul class="dropdown-menu">
      <li class="dropdown-item" v-for="item in props.items" :key="item" @click="() => emit('select', item)">
        <i class="bi icon-text" :class="itemIcon(item)">
          {{ props.getItemText(item) }}
        </i>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue';

const props = defineProps({
  items: Array,
  title: String,
  getItemText: {
    default: () => '',
    type: Function,
  },
  itemIcon: {
    default: () => '',
    type: Function,
  },
  titleIcon: String,
  showResetButton: Boolean,
});

const emit = defineEmits(['select', 'reset']);
</script>

<style lang="scss" scoped>
.dropdown-menu {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}

.icon-text {
  display: inline-flex;
  align-items: center;
  font-style: normal;
  gap: 8px;
}
</style>
