<template>
  <div class="legend-container">
    <div v-for="color in store.state.currentLegend" :key="color" class="legend-item user-select-none">
      <div class="legend-item-color" :style="{ backgroundColor: color?.[0] }"></div>
      <div>{{ color?.[1] }}</div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';

const store = useStore();
</script>

<style scoped lang="scss">
@import '../styles/variables.scss';

.legend-container {
  border-radius: $headerBorderRadius;
  padding: 8px;

  .legend-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 20px;

    &-color {
      border-radius: 50%;
      border: 1px solid #000f;
      width: 1rem;
      height: 1rem;
    }
  }
}
</style>
